<template>
  <div>
  <el-table
    :data="tableData"
    style="max-width: 1500px">
    <el-table-column
      prop="monster"
      label="怪物"
      width="120px">
    </el-table-column>
    <el-table-column label="武器">
        <el-table-column v-for="(value,key) in weapons" :prop="key" :label="value" :key="key">
        <template #default="scope">
            <el-tag size="medium" :type="getTagType(scope.row[key].highlight)" v-if="scope.row.hasOwnProperty(key)">
            <el-popover effect="light" trigger="hover" width=auto @show.once="layzLoadImg(scope.row.mcode, key)">
            <template #default>
                <p>规则: {{ scope.row[key].rule }}</p>
                <p v-if="scope.row[key].hasOwnProperty('version')">版本: {{ scope.row[key].version }}</p>
                <p v-if="scope.row[key].hasOwnProperty('remark')">备注: {{ scope.row[key].remark }}</p>
                <el-image ref="img" v-if="layz.hasOwnProperty(scope.row.mcode) && layz[scope.row.mcode][key]" :src="getImage(scope.row, key)" style="width: 800px; height: 450px" @click="fixPreview(getImage(scope.row, key))"/>
            </template>
            <template #reference>
                <div class="name-wrapper">
                    {{ scope.row[key].time }}
                </div>
            </template>
            </el-popover>
            </el-tag>
        </template>
        </el-table-column>
    </el-table-column>
  </el-table>
  <el-image
    ref="forpreview"
    class="forpreview"
    :hide-on-click-modal="true"
    :src="selected_img"
    :preview-src-list="[selected_img]"
    :z-index="8192"
    ></el-image>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        selected_img: null,
        tableData: null,
        weapons: {
            "greatsword": "大剑",
            "swordshield": "单手剑",
            "dualblade": "双剑",
            "longsword": "太刀",
            "hammer": "大锤",
            "huntinghorn": "狩猎笛",
            "lance": "长枪",
            "gunlance": "铳枪",
            "switchaxe": "斩击斧",
            "chargeblade": "充能斧",
            "insectglaive": "操虫棍",
            "bow": "弓",
            "heavybowgun": "重弩炮",
            "lightbowgun": "轻弩炮"
        },
        layz: {}
      }
    },
    methods: {
        fixPreview(image) {
          this.selected_img = image
          this.$refs.forpreview.clickHandler();
        },
        getImage(row, weapon) {
          if(row[weapon].image == null)
          {
            return `${process.env.VUE_APP_RES}/images/mhr/${row["mcode"]}_${weapon}.jpg`
          }
          return row[weapon].image
        },
        getTagType(highlight) {
          if(highlight != null && highlight == true)
          {
            return "danger"
          }
          return "success"
        },
        layzLoadImg(mcode,weapon) {
          if(!(mcode in this.layz)) {
              this.layz[mcode] = {}
          }
          this.layz[mcode][weapon] = true
          console.log(mcode+' '+weapon)
          console.log(this.layz)
        }
      },
    mounted(){
      this.axios.get(process.env.VUE_APP_API+'v1/mhr/records')
        .then(response => (this.tableData = response.data))
    },
  }
</script>

<style scoped>
.forpreview :deep() .el-image__inner {
    display: none;
}
.forpreview :deep() .el-image__error {
    display: none;
}
</style>